export enum CharterStatuses {
  Active = 'Active',
  DidNotReport = 'DidNotReport',
  NoLongerOperational = 'NoLongerOperational',
  NotYetActive = 'NotYetActive'
}

export class CharterStatusNames {
  [charterStatus: string]: string;

  constructor() {
    this[CharterStatuses.Active] = 'Active';
    this[CharterStatuses.DidNotReport] = 'Did Not Report';
    this[CharterStatuses.NoLongerOperational] = 'No Longer Operational'; 
    this[CharterStatuses.NotYetActive] = 'Not Yet Active'; 
  }
}
