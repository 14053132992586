import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';

import { NgResponse } from '../shared/ng-response';
import {
  Entity, EntityCharterNumber, EntityName, EntityRelationship, EntityResponse,
  EntitySubtype,
  EntityValidTimePeriod
} from '../shared/entity.model';
import { NgRequest } from '../shared/ng-request';
import { FilterSortPageRequest, FilterValuesRequest, FilterValuesResponse } from '../shared/grid.model';
import { EmptyRequest } from '../shared/empty-request';
import {
  EntitiesResponse, EntityDetailsNgRequest, EntityNamesResponse, EntityTimePeriodsResponse,
  EntityRelationshipsResponse, EntitySubtypesResponse, EntityCharterNumbersResponse
} from '../tools/entity-management/entity-management.model';
import { EmptyResponse } from '../shared/empty-response';
import { ReplaceItemRequest } from '../shared/replace-item-request';
import { AddEditItem, EntitySubtypeItem } from '../tools/entity-management/add-edit-modal/add-edit.model';

@Injectable()
export class EntityService extends HttpClientService {
  constructor(httpClient: HttpClient,
              httpErrorHandler: HttpErrorHandler) {
    super('EntityService', httpClient, httpErrorHandler);
  }

  getEntity(cdsCode: string | null = null): Observable<NgResponse<EntityResponse>> {
    if (cdsCode)
      return this.get<EntityResponse>('getEntity', `api/Security/Entity/${cdsCode}`);

    return this.get<EntityResponse>('getEntity', 'api/Security/Entity');
  }

  getOneSlim(includeChildren: boolean = false, cdsCode: string | null = null): Observable<NgResponse<EntityResponse>> {
    if (cdsCode)
      return this.get<EntityResponse>('getOneSlim', `api/Security/Entity/GetOneSlim/${includeChildren}/${cdsCode}`);

    return this.get<EntityResponse>('getOneSlim', `api/Security/Entity/GetOneSlim/${includeChildren}`);
  }


  fetchEntityItems(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<EntitiesResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, EntitiesResponse>('fetchEntityItems', `api/EntityManagement/Entities/Items`, request);
  }

  fetchEntityFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchEntityFilterValues', `api/EntityManagement/Entities/FilterValues`, request);
  }

  fetchEntityNameItems(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<EntityNamesResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, EntityNamesResponse>('fetchEntityNameItems', `api/EntityManagement/EntityNames/Items`, request);
  }

  fetchEntityNameFilterValues(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, FilterValuesResponse>('fetchEntityNameFilterValues', `api/EntityManagement/EntityNames/FilterValues`, request);
  }

  fetchEntityTimePeriodItems(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<EntityTimePeriodsResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, EntityTimePeriodsResponse>('fetchEntityTimePeriodItems', `api/EntityManagement/EntityTimePeriods/Items`, request);
  }

  fetchEntityTimePeriodFilterValues(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, FilterValuesResponse>('fetchEntityTimePeriodFilterValues', `api/EntityManagement/EntityTimePeriods/FilterValues`, request);
  }

  fetchEntityRelationshipItems(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<EntityRelationshipsResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, EntityRelationshipsResponse>('fetchEntityRelationshipItems', `api/EntityManagement/EntityRelationships/Items`, request);
  }

  fetchEntityRelationshipFilterValues(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, FilterValuesResponse>('fetchEntityRelationshipFilterValues', `api/EntityManagement/EntityRelationships/FilterValues`, request);
  }

  fetchEntitySubtypeItems(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<EntitySubtypesResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, EntitySubtypesResponse>('fetchEntitySubtypeItems', `api/EntityManagement/EntitySubtypes/Items`, request);
  }

  fetchEntitySubtypeFilterValues(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, FilterValuesResponse>('fetchEntitySubtypeFilterValues', `api/EntityManagement/EntitySubtypes/FilterValues`, request);
  }

  fetchEntityCharterNumberItems(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<EntityCharterNumbersResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, EntityCharterNumbersResponse>('fetchEntityCharterNumberItems', `api/EntityManagement/EntityCharterNumbers/Items`, request);
  }

  fetchEntityCharterNumberFilterValues(request: NgRequest<FilterSortPageRequest<EntityDetailsNgRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterSortPageRequest<EntityDetailsNgRequest>, FilterValuesResponse>('fetchEntityCharterNumberFilterValues', `api/EntityManagement/EntityCharterNumbers/FilterValues`, request);
  }

  addEntity(entityRequest: NgRequest<Entity>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<Entity, EmptyResponse>('addEntity', 'api/EntityManagement/Entities', entityRequest);
  }

  addEntityItem(addEntityItemRequest: NgRequest<AddEditItem>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<AddEditItem, EmptyResponse>('addEntityItem', 'api/EntityManagement/Entities/Composite', addEntityItemRequest);
  }

  updateEntity(entityUpdateRequest: NgRequest<Entity>): Observable<NgResponse<EmptyResponse>> {
    return this.putBody<Entity, EmptyResponse>('updateEntity', 'api/EntityManagement/Entities', entityUpdateRequest);
  }

  replaceEntity(entityReplaceRequest: NgRequest<ReplaceItemRequest<Entity>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<Entity>, EmptyResponse>('replaceEntity', 'api/EntityManagement/Entities/Replace', entityReplaceRequest);
  }

  deleteEntity(entityDeleteRequest: NgRequest<Entity>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<Entity, EmptyResponse>('deleteEntity', 'api/EntityManagement/Entities/Delete', entityDeleteRequest);
  }

  addEntityName(entityNameRequest: NgRequest<EntityName>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntityName, EmptyResponse>('addEntityName', 'api/EntityManagement/EntityNames', entityNameRequest);
  }

  replaceEntityName(entityNameReplaceRequest: NgRequest<ReplaceItemRequest<EntityName>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<EntityName>, EmptyResponse>('replaceEntityName', 'api/EntityManagement/EntityNames/Replace', entityNameReplaceRequest);
  }

  deleteEntityName(entityNameDeleteRequest: NgRequest<EntityName>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntityName, EmptyResponse>('deleteEntityName', 'api/EntityManagement/EntityNames/Delete', entityNameDeleteRequest);
  }

  addEntityTimePeriod(entityTimePeriodRequest: NgRequest<EntityValidTimePeriod>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntityValidTimePeriod, EmptyResponse>('addEntityTimePeriod', 'api/EntityManagement/EntityTimePeriods', entityTimePeriodRequest);
  }

  replaceEntityTimePeriod(entityTimePeriodReplaceRequest: NgRequest<ReplaceItemRequest<EntityValidTimePeriod>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<EntityValidTimePeriod>, EmptyResponse>('replaceEntityTimePeriod', 'api/EntityManagement/EntityTimePeriods/Replace', entityTimePeriodReplaceRequest);
  }

  deleteEntityTimePeriod(entityTimePeriodDeleteRequest: NgRequest<EntityValidTimePeriod>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntityValidTimePeriod, EmptyResponse>('deleteEntityTimePeriod', 'api/EntityManagement/EntityTimePeriods/Delete', entityTimePeriodDeleteRequest);
  }

  addEntityRelationship(entityRelationshipRequest: NgRequest<EntityRelationship>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntityRelationship, EmptyResponse>('addEntityRelationship', 'api/EntityManagement/EntityRelationships', entityRelationshipRequest);
  }

  replaceEntityRelationship(entityRelationshipReplaceRequest: NgRequest<ReplaceItemRequest<EntityRelationship>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<EntityRelationship>, EmptyResponse>('replaceEntityRelationship', 'api/EntityManagement/EntityRelationships/Replace', entityRelationshipReplaceRequest);
  }

  deleteEntityRelationship(entityRelationshipDeleteRequest: NgRequest<EntityRelationship>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntityRelationship, EmptyResponse>('deleteEntityRelationship', 'api/EntityManagement/EntityRelationships/Delete', entityRelationshipDeleteRequest);
  }

  addEntitySubtype(entitySubtypeRequest: NgRequest<EntitySubtype>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntitySubtype, EmptyResponse>('addEntitySubtype', 'api/EntityManagement/EntitySubtypes', entitySubtypeRequest);
  }

  replaceEntitySubtype(entitySubtypeReplaceRequest: NgRequest<ReplaceItemRequest<EntitySubtype>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<EntitySubtype>, EmptyResponse>('replaceEntitySubtype', 'api/EntityManagement/EntitySubtypes/Replace', entitySubtypeReplaceRequest);
  }

  deleteEntitySubtype(entitySubtypeDeleteRequest: NgRequest<EntitySubtype>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntitySubtype, EmptyResponse>('deleteEntitySubtype', 'api/EntityManagement/EntitySubtypes/Delete', entitySubtypeDeleteRequest);
  }

  addEntityCharterNumber(entityCharterNumberRequest: NgRequest<EntityCharterNumber>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntityCharterNumber, EmptyResponse>('addEntityCharterNumber', 'api/EntityManagement/EntityCharterNumbers', entityCharterNumberRequest);
  }

  replaceEntityCharterNumber(entityCharterNumberRequest: NgRequest<ReplaceItemRequest<EntityCharterNumber>>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<ReplaceItemRequest<EntityCharterNumber>, EmptyResponse>('replaceEntityCharterNumber', 'api/EntityManagement/EntityCharterNumbers/Replace', entityCharterNumberRequest);
  }

  deleteEntityCharterNumber(entityCharterNumberRequest: NgRequest<EntityCharterNumber>): Observable<NgResponse<EmptyResponse>> {
    return this.postBody<EntityCharterNumber, EmptyResponse>('deleteEntityCharterNumber', 'api/EntityManagement/EntityCharterNumbers/Delete', entityCharterNumberRequest);
  }
}
