import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppContext } from '../app.context';
import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';
import { Observable } from 'rxjs';
import { NgResponse } from '../shared/ng-response';
import { ZipFilesManagementResponse } from '../shared/zip-files-management.model';

@Injectable()
export class TableImportExportService extends HttpClientService {

  constructor(readonly httpClient: HttpClient,
    readonly appContext: AppContext,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('TableImportExportService', httpClient, httpErrorHandler);
  }

  tablesExport(): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('tablesExport', 'api/Tables/Export');
  }
}
