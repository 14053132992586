import { ReportingPeriods } from './reporting-periods';
import { SubmissionStateTypes } from './submission-state-types';
import { SubmissionStates } from './submission-states';

export class SubmissionsScope {
  timePeriodsStateGroups: Array<SubmissionsScopeTimePeriodStateGroups>;

  constructor(init?: Partial<SubmissionsScope>) {
    Object.assign(this, init);
  }
}

export class SubmissionsScopeTimePeriodStateGroups {
  fullFiscalYear: string;
  reportingPeriodsStateGroups: Array<SubmissionsScopeReportingPeriodStateGroups>;

  constructor(init?: Partial<SubmissionsScopeTimePeriodStateGroups>) {
    Object.assign(this, init);
  }
}

export class SubmissionsScopeReportingPeriodStateGroups {
  reportingPeriod: ReportingPeriods;
  stateGroups: Array<SubmissionsScopeStateGroup>;

  constructor(init?: Partial<SubmissionsScopeReportingPeriodStateGroups>) {
    Object.assign(this, init);
  }
}

export class SubmissionsScopeStateGroup {
  stateType: SubmissionStateTypes;
  states: Array<SubmissionStates>;

  constructor(init?: Partial<SubmissionsScopeStateGroup>) {
    Object.assign(this, init);
  }
}

export class SubmissionsScopeApiResponse extends SubmissionsScope {
  constructor(init?: Partial<SubmissionsScopeApiResponse>) {
    super(init);
    Object.assign(this, init);
  }
}

export class SubmissionsScopeApiRequest extends SubmissionsScope {
  constructor(init?: Partial<SubmissionsScopeApiRequest>) {
    super(init);
    Object.assign(this, init);
  }
}

export enum SubmissionsScopeSelectionLevel {
  FullFiscalYear,
  ReportingPeriod,
  SubmissionStateType,
  SubmissionState
}
