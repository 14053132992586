import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmptyResponse } from '../shared/empty-response';
import { FormDefinitionsResponse } from '../shared/form-definition.model';
import { FormGroupsDefinitionsResponse } from '../shared/form-group-definition.model';
import {
  ComputeBlankFormGroupsRequest,
  ComputeBlankFormRequest,
  ComputeFormGroupsRequest,
  ComputeFormRequest,
  FormGroupsResponse,
  FormResponse,
  UpdateBlankFormRequest,
  UpdateFormRequest
} from '../shared/form.model';
import { IFCDefinitionsResponse } from '../shared/ifc-definition.model';
import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { PrintBlankFormRequest } from '../shared/printing/print-blank-form-request';
import { PrintFormRequest } from '../shared/printing/print-form-request';
import { PrintTRCRequest } from '../shared/printing/print-trc-request';
import { ReportingPeriods } from '../shared/reporting-periods';
import { RunModes } from '../shared/runs-modes';
import { SELPAMemberSubmissionRequest } from '../shared/selpa-review.model';
import { UpdateSubmissionOnFormSaveRequest, UpdateSubmissionOnFormSaveResponse } from '../shared/submission.model';
import { TRCDefinitionsResponse } from '../shared/trc-definition.model';
import { TRCGroupDefinitionsResponse } from '../shared/trc-group-definition.model';
import { TRCRerunStatusNotificationResponse } from '../shared/trc/trc-log.model';
import {
  ComputeTRCGroupsNgRequest, ComputeTRCsNgRequest, SACSStringError,
  SACSStringValidationNgRequest,
  TRCGroup
} from '../shared/trc/trc-model';
import { VersionedComponentTypes } from '../shared/versioned-component-types';
import { ComponentSettings, ComponentVersionHistory, SystemVersionData } from '../tools/multi-year-settings/multi-year-settings.model';
import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';

@Injectable()
export class ComputeService extends HttpClientService {
  constructor(
    readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('ComputeService', httpClient, httpErrorHandler);
  }

  computeFormGroups(request: NgRequest<ComputeFormGroupsRequest>): Observable<NgResponse<FormGroupsResponse>> {
    return this.postBody<ComputeFormGroupsRequest, FormGroupsResponse>('computeFormGroups', 'api/FormGroups', request);
  }

  computeForm(request: NgRequest<ComputeFormRequest>): Observable<NgResponse<FormResponse>> {
    return this.postBody<ComputeFormRequest, FormResponse>('computeForm', 'api/Form', request);
  }

  computeBlankFormGroups(request: NgRequest<ComputeBlankFormGroupsRequest>): Observable<NgResponse<FormGroupsResponse>> {
    return this.postBody<ComputeBlankFormGroupsRequest, FormGroupsResponse>('computeBlankFormGroups', 'api/BlankFormGroups', request);
  }

  computeBlankForm(request: NgRequest<ComputeBlankFormRequest>): Observable<NgResponse<FormResponse>> {
    return this.postBody<ComputeBlankFormRequest, FormResponse>('computeBlankForm', 'api/BlankForm', request);
  }

  updateForm(request: NgRequest<UpdateFormRequest>): Observable<NgResponse<FormResponse>> {
    return this.putBody<UpdateFormRequest, FormResponse>('updateForm', 'api/Form', request);
  }

  updateBlankForm(request: NgRequest<UpdateBlankFormRequest>): Observable<NgResponse<FormResponse>> {
    return this.putBody<UpdateBlankFormRequest, FormResponse>('updateBlankForm', 'api/BlankForm', request);
  }

  updateSubmissionOnFormSave(updateSubmissionOnFormSaveRequest: NgRequest<UpdateSubmissionOnFormSaveRequest>): Observable<NgResponse<UpdateSubmissionOnFormSaveResponse>> {
    return this.putBody<UpdateSubmissionOnFormSaveRequest, UpdateSubmissionOnFormSaveResponse>('updateSubmissionOnFormSave', 'api/Form/UpdateSubmissionOnSave', updateSubmissionOnFormSaveRequest);
  }

  computeTRCGroups(computeTRCGroupsRequest: NgRequest<ComputeTRCGroupsNgRequest>): Observable<NgResponse<TRCGroup[]>> {
    return this.postBody<ComputeTRCGroupsNgRequest, TRCGroup[]>('TRCGroups/Compute', 'api/TRCGroups', computeTRCGroupsRequest);
  }

  computeTRCs(computeTRCsRequest: NgRequest<ComputeTRCsNgRequest>): Observable<NgResponse<TRCGroup[]>> {
    return this.postBody<ComputeTRCsNgRequest, TRCGroup[]>('TRCs/Compute', 'api/TRCs', computeTRCsRequest);
  }

  validateSACSString(computeTRCsRequest: NgRequest<SACSStringValidationNgRequest>): Observable<NgResponse<SACSStringError[]>> {
    return this.postBody<SACSStringValidationNgRequest, SACSStringError[]>('validateSACSString', 'api/TRCGroup/StringValidation', computeTRCsRequest);
  }

  getFormGroupDefinitions(): Observable<NgResponse<FormGroupsDefinitionsResponse>> {
    return this.get<FormGroupsDefinitionsResponse>('getFormGroupsDefinitions', 'api/FormGroupsDefinitions');
  }

  getFormMetadataDefinitions(): Observable<NgResponse<FormDefinitionsResponse>> {
    return this.get<FormDefinitionsResponse>('getFormMetadataDefinitions', 'api/FormDefinitions/Metadata');
  }

  getIFCMetadataDefinitions(): Observable<NgResponse<IFCDefinitionsResponse>> {
    return this.get<IFCDefinitionsResponse>('getIFCMetadataDefinitions', 'api/IFCDefinitions/Metadata');
  }

  getTRCGroupDefinitions(): Observable<NgResponse<TRCGroupDefinitionsResponse>> {
    return this.get<TRCGroupDefinitionsResponse>('getTRCGroupDefinitions', 'api/TRCGroupsDefinitions');
  }

  getTRCMetadataDefinitions(): Observable<NgResponse<TRCDefinitionsResponse>> {
    return this.get<TRCDefinitionsResponse>('getTRCMetadataDefinitions', 'api/TRCDefinitions/Metadata');
  }

  getTRCsRerunStatus(submissionNumber: string): Observable<NgResponse<TRCRerunStatusNotificationResponse>> {
    return this.get<TRCRerunStatusNotificationResponse>('getTRCsRerunStatus', `api/TRCs/RerunStatus/${submissionNumber}`);
  }

  getSystemVersionData(runMode: string): Observable<NgResponse<SystemVersionData>> {
    return runMode
      ? this.get<SystemVersionData>('getSystemVersionData', `api/SystemVersion/Data/${runMode}`)
      : this.get<SystemVersionData>('getSystemVersionData', 'api/SystemVersion/Data');
  }

  getPrescanRecalcHistory(caFiscalYear: number, reportingPeriod: ReportingPeriods, runMode: RunModes): Observable<NgResponse<ComponentVersionHistory[]>> {
    return this.getComponentVersionHistory('getPrescanRecalcHistory', caFiscalYear, reportingPeriod, VersionedComponentTypes.PrescanRecalc, runMode);
  }

  getTRCHistory(caFiscalYear: number, reportingPeriod: ReportingPeriods, trcName: string, runMode: RunModes): Observable<NgResponse<ComponentVersionHistory[]>> {
    return this.getComponentVersionHistory('getTRCHistory', caFiscalYear, reportingPeriod, VersionedComponentTypes.TRC, runMode, trcName);
  }

  getIFCHistory(caFiscalYear: number, reportingPeriod: ReportingPeriods, ifcName: string, runMode: RunModes): Observable<NgResponse<ComponentVersionHistory[]>> {
    return this.getComponentVersionHistory('getIFCHistory', caFiscalYear, reportingPeriod, VersionedComponentTypes.IFC, runMode, ifcName);
  }

  getFormHistory(caFiscalYear: number, reportingPeriod: ReportingPeriods, formName: string, runMode: RunModes): Observable<NgResponse<ComponentVersionHistory[]>> {
    return this.getComponentVersionHistory('getFormHistory', caFiscalYear, reportingPeriod, VersionedComponentTypes.Form, runMode, formName);
  }

  getFormGroupsHistory(caFiscalYear: number, reportingPeriod: ReportingPeriods, runMode: RunModes): Observable<NgResponse<ComponentVersionHistory[]>> {
    return this.getComponentVersionHistory('getFormGroupsHistory', caFiscalYear, reportingPeriod, VersionedComponentTypes.FormGroups, runMode);
  }

  getTRCGroupsHistory(caFiscalYear: number, reportingPeriod: ReportingPeriods, runMode: RunModes): Observable<NgResponse<ComponentVersionHistory[]>> {
    return this.getComponentVersionHistory('getTRCGroupsHistory', caFiscalYear, reportingPeriod, VersionedComponentTypes.TRCGroups, runMode);
  }

  private getComponentVersionHistory(operation: string, caFiscalYear: number, reportingPeriod: ReportingPeriods, type: VersionedComponentTypes, runMode: RunModes, name?: string): Observable<NgResponse<ComponentVersionHistory[]>> {
    return name
      ? runMode
        ? this.get<ComponentVersionHistory[]>(operation, `api/SystemVersion/History/${type}/${caFiscalYear}/${reportingPeriod}/${name}?mode=${runMode}`)
        : this.get<ComponentVersionHistory[]>(operation, `api/SystemVersion/History/${type}/${caFiscalYear}/${reportingPeriod}/${name}`)
      : runMode
        ? this.get<ComponentVersionHistory[]>(operation, `api/SystemVersion/History/${type}/${caFiscalYear}/${reportingPeriod}?mode=${runMode}`)
        : this.get<ComponentVersionHistory[]>(operation, `api/SystemVersion/History/${type}/${caFiscalYear}/${reportingPeriod}`);
  }

  updateComponentSettings(request: NgRequest<ComponentSettings>): Observable<NgResponse<FormResponse>> {
    return this.postBody<ComponentSettings, FormResponse>('updateComponentSettings', 'api/SystemVersion/ComponentSettings', request);
  }

  createNewSystemVersion(newVersion: string, runMode?: RunModes): Observable<NgResponse<SystemVersionData>> {
    return runMode
      ? this.get<SystemVersionData>('createNewSystemVersion', `api/SystemVersion/New/${newVersion}/${runMode}`)
      : this.get<SystemVersionData>('createNewSystemVersion', `api/SystemVersion/New/${newVersion}`);
  }

  rollbackSystemVersion(runMode?: RunModes): Observable<NgResponse<SystemVersionData>> {
    return runMode
      ? this.delete<SystemVersionData>('rollbackSystemVersion', `api/SystemVersion/${runMode}`)
      : this.delete<SystemVersionData>('rollbackSystemVersion', 'api/SystemVersion');
  }

  createTimePeriod(caFiscalYear: number, reportingPeriod: ReportingPeriods, runMode?: RunModes): Observable<NgResponse<EmptyResponse>> {
    return runMode
      ? this.post<EmptyResponse>('createTimePeriod', `api/TimePeriod/${caFiscalYear}/${reportingPeriod}/${runMode}`)
      : this.post<EmptyResponse>('createTimePeriod', `api/TimePeriod/${caFiscalYear}/${reportingPeriod}`);
  }

  deleteTimePeriod(caFiscalYear: number, reportingPeriod: ReportingPeriods, runMode?: RunModes): Observable<NgResponse<EmptyResponse>> {
    return runMode
      ? this.delete<EmptyResponse>('deleteTimePeriod', `api/TimePeriod/${caFiscalYear}/${reportingPeriod}/${runMode}`)
      : this.delete<EmptyResponse>('deleteTimePeriod', `api/TimePeriod/${caFiscalYear}/${reportingPeriod}`);
  }

  formPreview(printRequest: NgRequest<PrintFormRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<PrintFormRequest>('formPreview', 'api/Form/Preview', printRequest);
  }

  formExport(printRequest: NgRequest<PrintFormRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<PrintFormRequest>('formExport', 'api/Form/Export', printRequest);
  }

  blankFormPreview(printRequest: NgRequest<PrintBlankFormRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<PrintBlankFormRequest>('blankFormPreview', 'api/BlankForm/Preview', printRequest);
  }

  blankFormExport(printRequest: NgRequest<PrintBlankFormRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<PrintBlankFormRequest>('blankFormExport', 'api/BlankForm/Export', printRequest);
  }

  trcPreview(printRequest: NgRequest<PrintTRCRequest>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<PrintTRCRequest>('trcPreview', 'api/TRCs/Preview', printRequest);
  }

  getFormBySubmissionId(request: NgRequest<SELPAMemberSubmissionRequest>): Observable<NgResponse<FormResponse>> {
    return this.postBody<SELPAMemberSubmissionRequest, FormResponse>('getFormBySubmissionId', `api/Form/SELPAMemberSubmission`, request);
  }

  saveFormulaVersion(name: string, version: number, formulaVersion: string): Observable<NgResponse<EmptyResponse>> {
    return this.put<EmptyResponse>('saveFormulaVersion', `api/FormDefinitions/FormulaVersion/${name}/${version}/${formulaVersion}`);
  }

  deleteFormulaVersion(name: string, version: number): Observable<NgResponse<EmptyResponse>> {
    return this.delete<EmptyResponse>('deleteFormulaVersion', `api/FormDefinitions/FormulaVersion/${name}/${version}`);
  }
}
