import { BatchJobTypes } from './batch-job-types';
import { SubmissionsScope } from './statewide-batch-operation.model';

export enum TRCGroups {
  All = 'All',
  Import = 'Import',
  GeneralLedger = 'General Ledger',
  Supplemental = 'Supplemental',
  ExportValidation = 'Export Validation'
}

export class BatchDialogData {
  showOfficialSelection: boolean;

  constructor(init?: Partial<BatchDialogData>) {
    Object.assign(this, init);
  }
}

export class BatchDialogResults {
  name: string;
  isOfficial: boolean;

  constructor(init?: Partial<BatchDialogResults>) {
    Object.assign(this, init);
  }
}

export interface IBatchJobParameters { }

export class BatchExportParameters implements IBatchJobParameters {
  submissionNumbers: Array<string>;
  isOfficial: boolean;

  constructor(init?: Partial<BatchExportParameters>) {
    Object.assign(this, init);
  }
}

export class BatchTRCParameters implements IBatchJobParameters {
  submissionNumbers: Array<string>;

  constructor(init?: Partial<BatchTRCParameters>) {
    Object.assign(this, init);
  }
}

export class BatchQuerySACSParameters implements IBatchJobParameters {
  submissionNumbers: Array<string>;
  fullFiscalYear: string;

  constructor(init?: Partial<BatchQuerySACSParameters>) {
    Object.assign(this, init);
  }
}

export class BatchRunReportsParameters extends SubmissionsScope implements IBatchJobParameters {
  constructor(init?: Partial<BatchRunReportsParameters>) {
    super(init);
    Object.assign(this, init);
  }
}

export class BatchExportUserDataParameters extends SubmissionsScope implements IBatchJobParameters {
  contextFullFiscalYear: string;

  constructor(init?: Partial<BatchExportUserDataParameters>) {
    super(init);
    Object.assign(this, init);
  }
}

export class BatchJobApiRequest {
  name: string;
  type: BatchJobTypes;
  parameters?: string;

  constructor(init?: Partial<BatchJobApiRequest>) {
    Object.assign(this, init);
  }
}
